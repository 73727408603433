





















import { Component, Vue, Prop } from 'vue-property-decorator'
import { GvdSection } from '@/store/modules/gvd'

@Component
export default class EditGvdSection extends Vue {
  @Prop() gvdSection!: GvdSection;

  saveChanges () {
    this.$bvModal
      .msgBoxConfirm('Are you sure you wish to save changes?', {
        size: 'sm',
        buttonSize: 'sm',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(value => {
        if (!value) {
          return
        }
        this.$store.dispatch('saveGvdSection', this.gvdSection).then(() => {
          this.$bvModal.hide('edit-gvd-section-modal')
        })
      })
  }
}
