








































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { Product } from '@/store/modules/product'

@Component
export default class ManageProduct extends Vue {
  @Prop() product!: Product;
  newMarketKey: string = ''
  dismissSecs: number = 5;
  dismissCountDown: number = 0;
  countDownChanged (dismissCountDown: number) {
    this.dismissCountDown = dismissCountDown
  }

  get regions () {
    return this.$store.getters.getRegions
  }

  closeModal () {
    this.$bvModal.hide('manage-product-modal')
  }

  saveChanges () {
    this.$bvModal
      .msgBoxConfirm('Are you sure you wish to save changes?', {
        size: 'sm',
        buttonSize: 'sm',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(value => {
        if (!value) {
          return
        }
        this.$store.dispatch('saveProduct', this.product)
      })
  }

  deleteMarket (marketKey: string) {
    this.$bvModal
      .msgBoxConfirm('Are you sure you wish to delete this market association?', {
        size: 'sm',
        buttonSize: 'sm',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(value => {
        if (!value) {
          return
        }
        this.$emit('delete-product-market', marketKey)
      })
  }

  addMarket () {
    this.$bvModal
      .msgBoxConfirm('Are you sure you wish to add this market association?', {
        size: 'sm',
        buttonSize: 'sm',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(value => {
        if (!value) {
          return
        }
        this.$emit('add-product-market', this.newMarketKey)
        this.newMarketKey = ''
      })
  }
}
